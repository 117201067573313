import storage from "redux-persist/es/storage";
import { persistStore, persistReducer } from "redux-persist";
import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from "./reducers/reducers";
import { createStore } from "redux";




export default () => {

    const persistConfig = {
        key: "root",
        storage
    };
    const persistedReducer = persistReducer(persistConfig, reducers);
    const store = createStore(persistedReducer, applyMiddleware(thunk));
    const persistor = persistStore(store);

    return { store, persistor }
}