import React from 'react';
import './App.css';
import { Switch, Route } from "react-router-dom";
import DeeplinkValidationContainer from './containers/deeplink/DeeplinkValidationContainer';
import { Provider } from 'react-redux';
import createStore from './createStore';
import { PersistGate } from 'redux-persist/integration/react';
import LessonContainer from './containers/deeplink/LessonContainer';

const { store, persistor } = createStore()

function App() {
    return (
        <Provider store={store} >
            <PersistGate loading={null} persistor={persistor} >
                <div>
                    <Switch >
                        <Route exact path="/web"
                            component={DeeplinkValidationContainer}
                        />
                        <Route exact path="/lesson"
                            component={LessonContainer}
                        />
                        {/* <Route path="/apple-app-site-association" render={() => {window.location.href="apple-app-site-association.json"}}/> */}
                    </Switch>
                </div>
            </PersistGate>
        </Provider >
    );
}

export default App;
