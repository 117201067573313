import React from "react";
import { connect } from "react-redux";
import DeeplinkValidationComponent from "../../components/deeplink/DeeplinkValidationComponent";


const DeeplinkValidationContainer = props => {
    return <DeeplinkValidationComponent {...props}
    />;
};

const mapStateToProps = state => {
    let props = {};
    return props;
};

const mapDispatchToProps = (dispatch, props) => ({

});
/********************* END ********************/

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeeplinkValidationContainer);
