export const SET_USER = 'SET_USER';
export const SET_USER_TYPE = 'SET_USER_TYPE'
export const SET_CURRENT_SCREEN = 'SET_CURRENT_SCREEN'
export const SET_SCREEN_HEADING = 'SET_SCREEN_HEADING'
export const SET_IS_GUEST = 'SET_IS_GUEST'
export const SET_SUBJECT = 'SET_SUBJECT'
export const SET_CLASSES = 'SET_CLASSES'
export const SET_OLD_USER = 'SET_OLD_USER'

export const SET_SSS_TIME = 'SET_SSS_TIME'

export const SET_DEVICE_ID = 'SET_DEVICE_ID'

export const SET_SOCIAL_TIME = 'SET_SOCIAL_TIME'

export const SET_UPLOAD_ID = 'SET_UPLOAD_ID'

export const SET_IS_UPLOADING = 'SET_IS_UPLOADING'

export const SET_USER_START = 'SET_USER_START'

export const SET_UPLOAD_TYPE = 'SET_UPLOAD_TYPE'

export const SET_FCM_USER = 'SET_FCM_USER'
export const SET_SCREENFULLFLAG = 'SET_SCREENFULLFLAG'
export const SET_CLASSINDEX = 'SET_CLASSINDEX'

/**
 * SET USER
 * @type {string}
 */

export const SET_USER_FAILURE = 'SET_USER_FAILURE'

export const SET_USER_SUCCESS = 'SET_USER_SUCCESS'


/**
 * FILE UPLOAD
 */

export const START_UPLOAD = 'START_UPLOAD'
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS'
export const UPLOAD_FAILED = 'UPLOAD_FAILED'
export const UPLOAD_COMPLETED = 'UPLOAD_COMPLETED'

export const SET_INIT_UPLOAD = 'INIT_UPLOAD'

/****
 * Connection Status
 */
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS'
export const ADD_OFFLINE_ACTION = 'ADD_OFFLINE_ACTIONS'
export const EMPTY_OFFLINE_ACTION = 'EMPTY_OFFLINE_ACTION'


export const SET_NOTIFICATON_COUNT = 'SET_NOTIFICATION_COUNT'

export const SET_SSS_DATA = 'SET_SSS_DATA'

export const SET_FCM_TOKEN = 'SET_FCM_TOKEN'

export const SET_UPDATE_TIME = 'SET_UPDATE_TIME'

export const SET_MAX_LIMIT = 'SET_MAX_LIMIT'
