import React, { useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route, Switch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from '../common/copyright';
import header from "../../assets/images/registrationHeader.png";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


export default function DeeplinkValidation({ validateSRNumber, setTextInputValue, error, registerSRNumber, open}) {

    const classes = useStyles();

    const history = useHistory();

    const [showRegistration, setShowRegistration] = useState(false)
    return (
        <div className={classes.root}>
            <Container component="main" maxWidth="xs" className={classes.authContainer}>
                <CssBaseline />
                <div className={classes.paper}>
                    Download Sampark Smartshala Mobile App using below links
                </div>
                <div class="page-content page-container" id="page-content">
                    <div class="padding">
                        <div class="row container d-flex justify-content-center downloadIconButton">
                            <div class="template-demo mt-2 downloadIconButton">
                                <button class="btn btn-outline-dark btn-icon-text" onClick={()=>{
                                    window.open('https://apps.apple.com/us/app/sampark-smart-shala/id1534989543')
                                }}>
                                    <i class="fa fa-apple btn-icon-prepend mdi36px"></i>
                                    <span class="d-inline-block text-left">
                                        <small class="font-weight-light d-block">Available on the</small>
                                            App Store
                                    </span>
                                </button>
                                <button class="btn btn-outline-dark btn-icon-text" onClick={()=>{
                                    window.open('https://play.google.com/store/apps/details?id=com.sf.app&hl=en_IN&gl=US')
                                }}>
                                    <i class="fa fa-android btn-icon-prepend mdi36px"></i>
                                    <span class="d-inline-block text-left">
                                        <small class="font-weight-light d-block">Get it on the</small>
                                            Google Play
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container >
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <footer className={classes.footer}>
                <Container maxWidth="sm">
                    <Copyright />
                </Container>
            </footer>
        </div >
    );
}

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundImage: `url(${header})`,
        //backgroundSize: 'cover',
        alignItems: 'center',
        backgroundPositionY: '-150px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        backgroundColor: "#fff"
    },
    authContainer: {
        marginTop: '150px'
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        BackgroundColor: "#eccc15"
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    mdi36px: {
        fontSize: 39,
        marginRight:10,
    },
    downloadIconButton: {
        padding: 50,
    },
    container: {
        marginTop:150,
    }
}));
