import React from "react";
import { connect } from "react-redux";
import LessonComponent from "../../components/lesson/LessonComponent";



const LessonContainer = props => {
    return <LessonComponent {...props}
    />;
};

const mapStateToProps = state => {
    let props = {};
    return props;
};

const mapDispatchToProps = (dispatch, props) => ({

});
/********************* END ********************/

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LessonContainer);
