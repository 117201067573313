import React, { Component } from "react";
import DeeplinkValidation from "./DeeplinkValidation";
import axios from "axios";
import { GET_VALID_ID_FOR_DEEPLINK } from "../../constants/apiContants";
import { getDecryptedValue } from "../../util/applicationService";

class DeeplinkValidationComponent extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        textInput: "",
        flag: false,
        data: [],
        showError: false,
        assessmentClasses: [],
        confirm: false,
        message: '',
        statusCode: 0,
        showServerError: false,
        noAssessmentId: false,
        showBusyIndicator: false
    };
    componentDidMount() {
        console.log(this.props.history);
        this.validateParams();
    }

    validateParams() {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        const endpoint = urlParams.get('e');
        console.log("id - - - - - - - ",id);
        console.log("endpoint - - - - - - - ",endpoint);
        if(id && endpoint) {
            // const url = GET_VALID_ID_FOR_DEEPLINK + "?endpoint=" + endpoint + "&id=" + id
            
            // axios.get(url).then(res => {
            //     console.log("res - - - - - -",res)
            //     if(res != "" && res != undefined) {
            //         console.log("res inside - - - - - -",res)
            //         window.open("https://app.samparksmartshala.org/"+endpoint+"/?id="+res.data,"_self");
            //     }
            // }).catch(err => {
            //     console.log("Error", err)
            // })

            const decryptedId = getDecryptedValue(id);
            if(decryptedId) {
                window.open("https://app.samparksmartshala.org/"+endpoint+"/?id="+decryptedId,"_self");
            }
        }
    }

    render() {
        return (
            <div>
                <DeeplinkValidation validateSRNumber={this.validate}
                    setTextInputValue={
                        (res) => this.setState({ textInput: res })}
                    flag={this.state.flag}
                    error={this.state.showError}
                    data={this.state.assessmentClasses}
                    open={this.state.showBusyIndicator}
                />
            </div>
        );
    }
}

export default DeeplinkValidationComponent;