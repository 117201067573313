const CryptoJS = require("crypto-js");

export function getDecryptedValue(receivedData = "") {
  console.log("encryptedData - - - - - ",receivedData)
    if(receivedData) {
      //To convert all spaces to + as browser is replacing all + to space 
      const receivedIdWithValidChar = receivedData.replace(/ /g, "+");
      const encryptedData = decodeURIComponent(receivedIdWithValidChar);
  
      //Decryption 
      const Key = CryptoJS.enc.Utf8.parse("6il7YCRSqIOB9NooY225lPKQ0KuAF/nkFX6cY3vJkS0=");
      const IV = CryptoJS.enc.Utf8.parse("0123456789ABCDEF");
      const decryptedText = CryptoJS.AES.decrypt(encryptedData, Key, {
        iv: IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      let receivedIdDecrypted = decryptedText.toString(CryptoJS.enc.Utf8);
      return receivedIdDecrypted;
    }
    return "";
  }